<template>
  <div class="print_template_detail">
    <div >
      <template v-if="isEditingName">
        <el-input ref="templateNameInput"
          v-model="template.name"
          style="width:180px"
          @blur="toggleEditState"/>
      </template>
      <div v-else
        class="name_input"
        :title="template.name"
        @click="toggleEditState">
        <span>{{template.name}}</span>
        <i class="el-icon-edit-outline"  ></i>
      </div>
      <div>
         <!-- <el-button type="primary" plain @click="handlPreview">预览</el-button> -->
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="handleCloseTab">取消</el-button>
      </div>
    </div>
    <div>
      <div>
        <div>表单字段</div>
        <el-tree :data="fieldTree"
          node-key="__config__.renderKey"
          :props="{
            label:function (data){
              return data.__config__.label
            },
            children:function(data){
              return data.__config__.children
            }
          }"
          :render-content="renderFiledTreeItem">
        </el-tree>
      </div>
      <iframe :src="luckysheetPage"
        style="border:none;width:100%"
        ref="iframe"
        @load="handleIframeLoaded"/>
      <div>
        <div>打印属性</div>
        <el-form label-position="top" size="mini">
          <el-form-item label="纸张尺寸">
            <div>
               <el-select v-model="template.paperSize" @change="updatePagerSetting">
                <el-option value="A3">A3</el-option>
                <el-option value="A4">A4</el-option>
                <el-option value="A5">A5</el-option>
                <el-option value="A6">A6</el-option>
              </el-select>
              <div class="d-flex align-items-center">
                <i class="el-icon-warning-outline"></i>
                <span class="font-small ml-1">超出绿色标注线外，会导致打印不完整</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="纸张方向">
            <el-radio-group v-model="template.paperDirection" @change="updatePagerSetting">
              <el-radio-button label="LONGITUDINAL">纵向</el-radio-button>
              <el-radio-button label="TRANSVERSE">横向</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="页边距">
            <el-row :gutter="16">
              <el-col :span="12">
                <el-input v-model="template.topMargin" type="number" @blur="setDefaultMargin($event,'topMargin')">
                  <template slot="prepend">上</template>
                  <template slot="append">mm</template>
              </el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model="template.bottomMargin"  type="number" @blur="setDefaultMargin($event,'bottomMargin')">
                  <template slot="prepend">下</template>
                <template slot="append">mm</template>
              </el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model="template.leftMargin"  type="number" @blur="setDefaultMargin($event,'leftMargin')">
                  <template slot="prepend">左</template>
                <template slot="append">mm</template>
              </el-input>
              </el-col>
              <el-col :span="12">
                <el-input v-model="template.rightMargin"  type="number" @blur="setDefaultMargin($event,'rightMargin')">
                  <template slot="prepend">右</template>
                <template slot="append">mm</template>
              </el-input>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-transfer-dom class="pdf_viewer" v-show="previewDialogVisile" >
      <div class="pdf_viewer_mask" ></div>
      <div class="pdf_viewer_toolbar"  >
          <span>{{previewPdfPageIndex}} / {{previewPdfNums}}</span>
          <el-divider direction="vertical"></el-divider>
          <div>
            <i class="el-icon-back" @click="handlePerviewPageChange('pre')" title="上一页"></i>
            <i class="el-icon-right" @click="handlePerviewPageChange('next')" title="下一页"></i>
          </div>
          <el-divider direction="vertical"></el-divider>
          <span><i  class="el-icon-close" @click="previewDialogVisile = false" title="关闭"/></span>
      </div>
      <div class="pdf_viewer_container">
        <pdf v-if="previewDialogVisile"
        :src="previewPdfURL"
        :page="previewPdfPageIndex"/>
      </div>
    </div>
  </div>
</template>
<script>
import commonApi from '@/api/common';
import exportExcel from './exportExcel';
import TransferDom from '@/directives/transfer-dom';
import axios from 'axios';
import pdf from 'vue-pdf';
import { getSystemComponentFixedFields } from '@/components/form-generator/utils/component';
import { getCustomQueryColumn } from '@/api/commonForm';
import { queryLayoutByNodeApi, getPrintTemplateDetail, addPrintTemplate, editPrintTemplate, editPrintTemplateName, previewFormTemplate } from '@/api/formSetting';
export default {
  name: 'PrintTemplateDetail',
  components: {
    pdf,
  },
  directives: {
    TransferDom,
  },
  data () {
    return {
      action: 'edit',
      isEditingName: false,
      previewDialogVisile: false,
      previewPdfNums: 0,
      previewPdfPageIndex: 1,
      previewPdfURL: null,
      luckysheetPage: `${process.env.BASE_URL}luckysheet.html`,
      template: {
        name: '未命名',
        paperSize: 'A4',
        paperDirection: 'LONGITUDINAL',
        bottomMargin: 0,
        topMargin: 0,
        leftMargin: 0,
        rightMargin: 0,
        sheetSchema: {
          name: 'Sheet1',
          color: '',
          status: 0,
          order: '0',
          index: 'init_sheet',
        },
      },
      fieldTree: null,
    };
  },
  props: {
    templateCode: String,
    templateName: String,
    id: String,
  },
  computed: {
    routeTab () {
      let text = '';
      switch (this.action) {
      case 'new':
        text = '新增';
        break;
      case 'copy':
        text = '复制';
        break;
      case 'edit':
        text = '编辑';
        break;
      default:
        break;
      }
      return `${text}打印模板`;
    },
  },
  methods: {
    setDefaultMargin (e, field) {
      const targetValue = e.target.value;
      if (targetValue === '' || targetValue === undefined || targetValue === null) {
        e.target.value = 0;
        this.template[field] = 0;
      } else if (!isNaN(targetValue) && parseFloat(targetValue) < 0) {
        e.target.value = 0;
        this.template[field] = 0;
      }
      this.updatePagerSetting();
    },
    handlePerviewPageChange (action) {
      if (action === 'pre') {
        if (this.previewPdfPageIndex <= 1) return;
        this.previewPdfPageIndex--;
      } else {
        if (this.previewPdfPageIndex === this.previewPdfNums) return;
        this.previewPdfPageIndex++;
      }
    },
    handlPreview () {
      this.getExcelBlob().then(data => {
        let newForm = new FormData();
        Object.keys(this.template).forEach(key=>{
          newForm.set(key, this.template[key]);
        });
        newForm.set('multiFile', new Blob([data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        }));
        previewFormTemplate(newForm).then(({data})=>{
          this.previewPdfURL = pdf.createLoadingTask({
            url: URL.createObjectURL(data),
            cMapUrl: `${process.env.BASE_URL}libs/pdfjs/cmaps/`,
            cMapPacked: true,
          });
          this.previewPdfURL.promise.then(pdf => {
            this.previewPdfNums = pdf.numPages;
            this.previewDialogVisile = true;
            this.previewPdfPageIndex = 1;
          });
        });
      });
    },
    updatePagerSetting () {
      this.iframeWindow.postMessage({
        type: 'update',
        data: this.template,
      });
      this.iframeWindow.luckysheet.refresh();
    },
    // 切换编辑状态
    toggleEditState () {
      this.isEditingName = !this.isEditingName;
      if (this.isEditingName) {
        this.$nextTick(()=>{
          this.$refs.templateNameInput.focus();
        });
      } else {
        // 非编辑态，没有id，故走统一保存逻辑
        if (this.action === 'edit') {
          editPrintTemplateName({
            id: this.id,
            name: this.template.name,
          }).then(()=>{
            this.$message.success('模板名称修改成功');
            this.isEditingName = false;
          });
        } else {
          this.isEditingName = false;
          return;
        }
      }
    },
    async getFieldTree (fields, map = {}, parent) {
      let ret = [];
      if (fields) {
        for (const node of fields) {
          node.fullKey = (parent?.fullKey ? (parent?.fullKey + '.') : '') + node.__config__.renderKey;
          if (node?.__config__?.layout === 'groupRowFormItem') {
            ret = ret.concat(await this.getFieldTree(node?.__config__?.children, map));
          } else if (node?.__config__?.layout === 'tableColumnFormItem') {
            node.__config__.children = await this.getFieldTree(node.__config__.children[0]?.fields || [], map, node);
            ret.push(node);
          } else if (node?.__config__?.layout === 'childFormItem') {
            const rep = await getCustomQueryColumn({
              customizeSystemConfigCode: node?.relatedFormType,
              customizeBusinessTypeCode: node?.relatedFormTemplateCode,
            });
            const fields = rep.body?.map((col)=>({
              __config__: {
                renderKey: col.columnKey,
                tag: col.columnType,
                label: col.columnName,
              },
            })) || [];
            node.__config__.children = await this.getFieldTree(fields, map, node);
            ret.push(node);
          } else if (node?.__config__?.tag === 'c-work-hour' || node?.__config__?.tag === 'c-spare-part-list') {
            const fields = getSystemComponentFixedFields(node);
            node.__config__.children = await this.getFieldTree(fields, map, node);
            ret.push(node);
          } else if (node?.__config__?.tag !== 'upload') {
            node.fullName = (parent?.__config__.label ? (parent?.__config__.label + '.') : '') + node.__config__.label;
            map[node.fullName] = node;
            ret.push(node);
          }
        }
      }

      return ret;
    },
    renderFiledTreeItem (h, { node, data }) {
      // 禁用业务组件、关联表单字段拖拽，仅可拖拽其子字段属性
      const className = `field_tree_item ${data && data?.__config__.children && data?.__config__.children[0] ? 'stop_draggable' : ''}`;
      const draggable = data && (!data?.__config__.children || !data?.__config__.children[0]);
      return (
        <div class={className} draggable={draggable}
          onDragstart={(e)=>e.dataTransfer.setData('text/plain', draggable ? data.fullName : '')}>
          {

            draggable ? <span title={node.label} class="flex-fill text-ellipsis">{node.label}</span>
              : <el-tooltip effect="dark" content="请展开下级字段进行拖拽" placement="right-start" open-delay={500}>
                <span title={node.label} class="flex-fill text-ellipsis">{node.label}</span>
              </el-tooltip>
          }

          {
            node.isLeaf ? null : (
              node.expanded ? <i class="el-icon-arrow-up"></i> : <i class="el-icon-arrow-down"></i>
            )
          }
        </div>);
    },
    handleCloseTab () {
      this.$router.go(-1);
    },
    getExcelBlob () {
      // 单元格值替换
      const cellFormat = (cellValue)=>{
        if (!cellValue) return cellValue;
        this.fieldMap && Object.keys(this.fieldMap).forEach(t=>{
          let format = `{${this.fieldMap[t].fullKey}}`;
          cellValue = cellValue.replace(`{${t}}`, format);
        });
        return cellValue;
      };
      return exportExcel(this.iframeWindow.luckysheet, cellFormat);
    },
    handleSave () {
      const getUploadToken = commonApi.getQinNiuToken();
      const getExcelBlob = this.getExcelBlob();
      Promise.all([getUploadToken, getExcelBlob]).then(ret=>{
        // 处理七牛token
        const tokenRep = ret[0];
        const qiniuToken = tokenRep.body.token;
        const downloadDomain = tokenRep.body.domain;
        // 处理excel文件流
        const excelBuffer = ret[1];
        const blob = new Blob([excelBuffer], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        });
        const filename = `print_template/${this.templateCode}/${Date.now()}.xlsx`;
        let newForm = new FormData();
        newForm.set('file', blob);
        newForm.set('key', filename);
        newForm.set('token', qiniuToken);
        axios.post('https://upload.qiniup.com', newForm).then(res=>{
          const attachUrl = `${downloadDomain}/${res.data.key}`;
          let request = null;
          if (this.action === 'edit') {
            request = editPrintTemplate;
          } else {
            request = addPrintTemplate;
          }
          request({
            ...this.template,
            customizeBusinessTypeCode: this.templateCode,
            attachUrl,
          }).then(()=>{
            this.$message.success('打印模板保存成功');
            this.handleCloseTab();
          });
        });
      });
    },
    handleIframeLoaded (e) {
      this.iframeWindow = e.target.contentWindow;
      this.initHandler.then(()=>{
        this.iframeWindow.postMessage({
          type: 'init',
          data: this.template,
        });
      });
    },
    handleExcelSchemaUpdate (message) {
      if (message?.data?.type === 'syncSchema') {
        this.template.sheetSchema = message?.data?.data;
      }
    },
  },
  mounted () {
    this.action = this.$route.params.action;
    if (this.id) {
      this.initHandler = getPrintTemplateDetail({
        id: this.id,
      }).then(({body})=>{
        this.template = body || {};
        if (this.action === 'copy') {
          this.template.name = `${this.template.name || ''}-复制`;
        }
      });
    } else {
      this.initHandler = Promise.resolve();
    }
  },
  created () {
    queryLayoutByNodeApi({
      code: this.templateCode,
      status: 'END',
    }).then(({body})=>{
      let fields = body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc?.fields || [];
      this.fieldMap = {};
      this.getFieldTree(fields, this.fieldMap).then(data=>{
        this.fieldTree = data;
      });
    });
    window.addEventListener('message', this.handleExcelSchemaUpdate);
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleExcelSchemaUpdate);
  },
  beforePageLeave (tab, type) {
    if (type === 'unload') {
      return '打印模板尚未保存，确定要关闭吗？';
    }
    if (type === 'replace') {
      return this.$confirm('打印模板尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false })
        .then(() => {
          return Promise.resolve(() => this.$router.push(tab.to));
        })
        .catch(() => Promise.reject());
    } else {
      return this.$confirm('打印模板尚未保存，确定要关闭吗？', '提示', { closeOnHashChange: false }).then(() => {
        return Promise.resolve();
      })
        .catch(() => Promise.reject());
    }
  },
};
</script>
<style lang="scss" scoped>
.print_template_detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &>div:nth-child(1) {
    height: 48px;
    background: #fff;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 48px;
    &>div:last-child {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      display: flex;
      align-items: center;
    }

    .name_input{
      line-height: 32px;
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        padding: 0 15px;
        border-radius: 4px;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }
      &:hover span{
        border: 1px solid #dcdfe6;
      }
    }
  }

  &>div:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: row;
    background: white;
    overflow: hidden;
    &>div,&>iframe {
      height: 100%;
      flex: 1;
      &:first-child {
        border-top: 1px solid #dcdfe6;
        min-width: 206px;
        flex: 0;
        box-shadow: 2px 0px 8px 0px rgba(0,58,114,0.08);
        display: flex;
        flex-direction: column;
        &>div:first-child {
          padding: 0px 16px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(36,40,50,0.7);
        }

        &>.el-tree {
          flex: 1;
          overflow: auto;
          padding: 0px 16px;

          /deep/.el-tree-node__content {
            height: auto;
            background-color: transparent!important;
            .el-tree-node__expand-icon {
              display: none;
            }
            .field_tree_item {
              height: 28px;
              padding: 0 8px 0 12px;
              background: rgba(36,40,50,0.06);
              border-radius: 4px;
              font-size: 12px;
              font-weight: 400;
              color: #242832;
              line-height: 28px;
              margin-bottom: 8px;
              cursor: move;
              flex: 1;
              overflow: hidden;
              border:1px dashed transparent;
              display: flex;
              flex-direction: row;
              align-items: center;
              &.stop_draggable {
                cursor: no-drop;
                user-select: none;
              }
              &:hover {
                background: rgba(255,255,255,0.06);
                border-radius: 4px;
                border: 1px dashed $primary-color;
                color: $primary-color;
              }
            }
          }
        }
      }
      &:last-child {
        min-width: 278px;
        flex: 0;
        box-shadow: -2px 0px 11px 0px rgba(0,58,114,0.08);
        border-top: 1px solid #dcdfe6;
        &>div:first-child {
          padding: 0px 16px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(36,40,50,0.7);
          border-bottom: 1px solid rgba(36,40,50,0.16);
        }

        &>form {
          padding: 0px 16px;
          /deep/.el-form-item__content{
            .el-radio-group {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              .el-radio-button {
                flex: 1;
                .el-radio-button__inner {
                  width: 100%;
                }
              }
            }

            .el-input-group{
              .el-input-group__prepend {
                padding: 0 12px;
                background-color: transparent;
              }
              .el-input-group__append {
                 padding: 0 8px;
              }

              .el-input__inner {
                padding: 0 2px;
              }
            }

            .el-row .el-col {
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

.pdf_viewer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2017;

  .pdf_viewer_mask {
    height: 100%;
    width: 100%;
    opacity: .5;
    background:rgba(0,0,0,0.9);
    position: absolute;
    z-index: 1;
  }

  div.pdf_viewer_toolbar {
      position: fixed;
      left: 0;
      bottom: 12px;
      right: 0;
      text-align: center;
      z-index: 2;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: rgba(33,33,33,.7);
      width: 200px;
      margin:0 auto;
      padding:0px 12px;
      color:white;
      box-sizing: border-box;
      /deep/.el-divider {
        background: #B3B3B3;
        margin-left: 4px;
        margin-right: 4px;
      }
      i {
        font-size: 20px;
        padding:10px 10px;
        cursor: pointer;
        &:hover{
          background: rgba(31, 27, 27, 0.5);
          border-radius: 4px;
        }
      }

      &>span{
        flex:1;
          display: flex;
          justify-content: center;

        &:nth-child(1){

          font-size: 14px;
          font-weight: 400;
        }
      }

      &>div:nth-child(3) {
        display: flex;
        align-items: center;
        // width: 200px;
        &>i {
          flex: 1;
        }
      }
    }
  .pdf_viewer_container{
    position: absolute;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 36px calc((100% - 640px) / 2) 0;
    z-index: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    &>* {
      // flex: 1;
      // overflow: auto;
    }
  }
}
</style>
